<template>
  <NLayout class="h-screen" content-class="flex flex-col">
    <DefaultLayoutHeader />
    <NLayout class="grow" has-sider>
      <NLayoutSider
        :collapsed="isCollapsed"
        :collapsed-width="64"
        :native-scrollbar="false"
        :width="240"
        @collapse="collapseMenu"
        @expand="expandMenu"
        bordered
        collapse-mode="width"
        show-trigger
      >
        <NMenu
          :collapsed="isCollapsed"
          :collapsed-icon-size="22"
          :collapsed-width="64"
          :options="isCollapsed ? collapsedMenuOptions : expandedMenuOptions"
          v-model:value="selectedKey"
        />
      </NLayoutSider>
      <NLayoutContent>
        <slot />
      </NLayoutContent>
    </NLayout>
  </NLayout>
</template>

<script lang="ts" setup>
import { useRoute } from '#app';
import { NuxtLink } from '#components';
import { BookOnlineOutlined as BookIcon } from '@vicons/material';
import { useRouteParams } from '@vueuse/router';
import { NIcon, NLayout, NLayoutContent, NLayoutSider, NMenu } from 'naive-ui';
import { type Component, computed, h, ref, watch } from 'vue';

import DefaultLayoutHeader from '../components/layout/default/default-layout-header.vue';

defineSlots<{
  default: () => void;
}>();

const renderIcon = (icon: Component) => () => h(NIcon, null, { default: () => h(icon) });

const projectParams = useRouteParams('id');

const projectId = computed(() => (typeof projectParams.value === 'string' ? projectParams.value : ''));

const route = useRoute();

const isCollapsed = ref(false);

const selectedKey = ref('Milestones');

const collapseMenu = () => {
  isCollapsed.value = true;
};

const expandMenu = () => {
  isCollapsed.value = false;
};

const expandedMenuOptions = [
  {
    children: [
      {
        icon: renderIcon(BookIcon),
        key: 'Milestones',
        label: () => h(NuxtLink, { href: `/project/${projectId.value}/milestone` }, { default: () => 'Milestones' }),
      },
    ],
    key: 'project',
    label: 'Project',
    type: 'group',
  },
  {
    children: [
      {
        icon: renderIcon(BookIcon),
        key: 'Repository',
        label: () => h(NuxtLink, { href: `/project/${projectId.value}/repository` }, { default: () => 'Repository' }),
      },
    ],
    key: 'management',
    label: 'Test Management',
    type: 'group',
  },
];

const collapsedMenuOptions = [
  {
    icon: renderIcon(BookIcon),
    key: 'Milestones',
    label: () => h(NuxtLink, { href: `/project/${projectId.value}/milestone` }, { default: () => 'Milestones' }),
  },
  {
    icon: renderIcon(BookIcon),
    key: 'Repository',
    label: () => h(NuxtLink, { href: `/project/${projectId.value}/repository` }, { default: () => 'Repository' }),
  },
];

watch(
  () => route.path,
  (newRoutePath: string) => {
    if (newRoutePath.includes('milestone')) {
      selectedKey.value = 'Milestones';
    } else if (newRoutePath.includes('repository')) {
      selectedKey.value = 'Repository';
    }
  },
  { immediate: true },
);
</script>
